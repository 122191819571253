import React, { Fragment } from "react"
import { graphql } from "gatsby"
import { colors } from "../consts/style"
import { GatsbyImage } from "gatsby-plugin-image"
import Seo from "../components/seo"

import BtnPrimary from "../components/buttons/ButtonRounded"
import { FormattedMessage} from 'react-intl';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  GridAlSophro,
  ColGauche,
  ColDroite,
  Text,
  FocusText,
  Grid2Col,
  Spacer,
} from "../components/Elements"

export const contactQuery = graphql`
  query contactQuery($locale: String!) {
    

    page: datoCmsContactPage(locale: {eq: $locale}) {
      titre
      chapeau
      contenu
      encartContacts
      encartTransports
      illustration {
        gatsbyImageData(
          placeholder: BLURRED,
          forceBlurhash: false,           
        )
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`
const ContactPage =  ({ data }) => {

  const {
    titre,
    chapeau,
    contenu,
    illustration,
    encartContacts,
    encartTransports,
    seoMetaTags,
  } = data.page

  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
        <PageInner>
         
          
        </PageInner>
        <Spacer id="contact" />
      
          <PageInner>
            <GridAlSophro>
              <ColGauche>
                <GatsbyImage image={illustration.gatsbyImageData} alt={titre} style={{"marginBottom":"4rem"}}/>
                <Text
                  dangerouslySetInnerHTML={{ __html: encartContacts }}
                />
              </ColGauche>
              <ColDroite> 
                <PageTitle>{titre}</PageTitle>
                <FocusText
                  
                  dangerouslySetInnerHTML={{ __html: chapeau }}
                />
                <Text
                  color={colors.dark}
                  dangerouslySetInnerHTML={{ __html: contenu }}
                />
                <Text
                  dangerouslySetInnerHTML={{ __html: encartTransports }}
                />
              </ColDroite>
            </GridAlSophro>
          </PageInner>
        
      </PageWrapper>
    </Fragment>
  )
}

export default  ContactPage